import type { ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loading } from "~/components/common/Loading";
import type { UserProfile } from "~/server/db-types";
import type { Session } from "@supabase/auth-helpers-nextjs";
import { api } from "~/utils/api";
import { useRouter } from "next/router";
import type { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import * as Sentry from "@sentry/nextjs";
import { isSSR } from "~/utils/nextjs-utils";

type SupabaseType = ReturnType<typeof createPagesBrowserClient<any, any, any>>;

interface ConfigContextInterface {
  supabase: SupabaseType;
  config: Record<string, any>;
  userProfile: UserProfile | null;
  organizationId: string;
  supabaseSession: Session | null | undefined;
  expandSideMenu: boolean;
  reload: (query: string) => Promise<void>;
  setError: (error: Error) => void;
}

export const ConfigContext = createContext<ConfigContextInterface>({
  // @ts-ignore
  supabase: null,
  // not good idea to add flags here as will cause re-render all app on get foucs
  config: {},
  userProfile: null,
  organizationId: "INVALID",
  organizationData: undefined,
  supabaseSession: undefined,
  reload: async () => {},
  setError: () => {},
});

export const ConfigProvider = ({
  supabase,
  supabaseSession,
  authEventsReceived,
  pageNeedUser,
  pageNeedOrganizationData,
  expandSideMenu,
  children,
}: {
  supabase: SupabaseType;
  supabaseSession: Session | null | undefined;
  authEventsReceived: boolean;
  pageNeedUser: boolean;
  pageNeedOrganizationData: boolean;
  expandSideMenu: boolean;
  children: ReactNode;
}) => {
  const router = useRouter();
  const organizationId: string | undefined = router.query.orgId
    ? String(router.query.orgId)
    : undefined;

  // // not sure why this is needed
  // if (!organizationId) {
  //   const match = router.asPath.match(/^\/(\d+)/);
  //   if (match) {
  //     organizationId = match[1];
  //   }
  // }

  const [authReady, setAuthReady] = useState(false);
  const upsertUserProfile = api.auth.upsertUserProfile.useMutation();
  const {
    data: userProfile,
    refetch,
    isFetched,
  } = api.auth.getUserProfile.useQuery(
    { organizationId },
    {
      enabled: authReady,
      refetchOnWindowFocus: false,
    },
  );

  const userProfileId = userProfile?.id;

  // Later we let user choose organization and save in session storage
  // Should read here from session storage
  const organizationDataIsReady = !!organizationId;
  const [raiseError, setRaiseError] = useState<Error | null>(null);

  const onAuth = useCallback(
    async (sessionClient: Session | null) => {
      const id = sessionClient?.user?.id || null;
      // console.log(`muly:config-context:onAuth`, {
      //   sessionClient,
      //   userProfileId,
      //   id,
      // });

      if (id && userProfileId !== id) {
        console.log(`onAuth: muly:has id ${id} !== ${userProfileId}`, {
          userProfile,
          sessionClient,
        });

        const userReceived = await refetch();
        if (!userReceived.data) {
          console.log(
            `muly:onAuth user profile not found, should not happen often, try create the profile`,
          );
          await upsertUserProfile.mutateAsync();
          const userReceivedTake2 = await refetch();
          if (!userReceivedTake2) {
            console.error(`muly:onAuth user profile not found, take 2`);
            throw new Error(`muly:onAuth user profile not found, take 2`);
          }
        }
        console.log(`muly:has id, refetch ${id}`, {
          userReceived: userReceived.data,
        });

        // } else {
        // console.log(`muly:same user id, skip`);
      }

      if (pageNeedUser && !id && authEventsReceived) {
        console.log(`muly:should redirect to login page`, { sessionClient });
        await router.replace("/login");
      }

      setAuthReady(true);

      console.log(`muly:setAuthReady, ${id ? id : "NO USER"}`, {
        id,
        userProfile_STALED_fixed_before_refetch: userProfile,
        userProfileId,
        sessionClient,
        authEventsReceived,
        pageNeedUser,
      });

      // Sentry.setUser(
      //   id
      //     ? {
      //         id,
      //         email: sessionClient?.user?.email || "unknown",
      //         username: user?.name || sessionClient?.user?.email || "unknown",
      //       }
      //     : null,
      // );
      // Sentry.setTag("agency", organizationData?.name || "unknown");
    },
    [
      setAuthReady,
      userProfileId,
      refetch,
      upsertUserProfile,
      userProfile,
      authEventsReceived,
      pageNeedUser,
      router,
    ],
  );

  useEffect(() => {
    Sentry.setUser(
      userProfile
        ? {
            id: userProfile.id,
            email: userProfile.email || "unknown",
            username: userProfile.name || userProfile.email || "unknown",
          }
        : null,
    );
    Sentry.setTag("agency", userProfile?.organizationName || "unknown");
    console.log(`muly:config-context:Sentry`, {
      userProfileId,
      userProfile,
    });
  }, [userProfile, userProfileId]);

  useEffect(() => {
    console.log(`muly:config-context:useffect`, {
      supabaseSession,
      authEventsReceived,
    });
    if (authEventsReceived) {
      onAuth(supabaseSession || null).catch((err) => {
        console.error(`muly:onAuthStateChange:onAuth:error`, err);
      });
    }
    // don't add onAuth to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabaseSession, authEventsReceived /*, onAuth*/]);

  useEffect(() => {
    if (raiseError) {
      // Sentry.captureException(raiseError);
      throw raiseError;
    }
  }, [raiseError]);

  if (
    /*!flagBag.flags || */
    // supabaseSession === undefined ||
    !authReady ||
    (!isFetched && organizationId) ||
    (pageNeedUser && !userProfile) ||
    (pageNeedOrganizationData && !organizationDataIsReady)
  ) {
    let reason = "unknown";
    if (!authReady) {
      reason = "not authReady";
    } else if (authReady && !isFetched) {
      reason = "authReady but not isFetched";
    } else if (pageNeedUser && !userProfile) {
      reason = "pageNeedUser but no userProfile";
    } else if (pageNeedOrganizationData && !organizationDataIsReady) {
      reason = "pageNeedOrganizationData but no organizationDataIsReady";
    }
    if (!isSSR()) {
      console.log(`LOADING ${reason}`, {
        authReady,
        organizationId,
        query: router.query,
        supabaseSession,
        authEventsReceived,
      });
    }
    // console.log(`muly:ConfigProvider:Loading`, {
    //   authEventsReceived,
    //   authReady,
    //   userProfile,
    //   pageNeedUser,
    //   pageNeedOrganizationData,
    //   supabaseSession,
    //   organizationId,
    //   organizationDataIsReady,
    //   organizationData,
    // });
    return <Loading />;
  }

  const reload = async (topic: string) => {
    console.log(`muly:ConfigProvider:reload`, { topic });
    if (topic === "UserProfile") {
      const answer = await refetch();
      console.log(
        `muly:ConfigProvider:reload ${userProfile?.organizationName}`,
        { userProfile, answer },
      );
    }
  };

  const value: ConfigContextInterface = {
    supabase,
    userProfile: userProfile || null,
    supabaseSession,
    config: {},
    organizationId: organizationId || "INVALID",
    expandSideMenu,
    reload,
    setError: setRaiseError,
  };

  console.log(`muly:ConfigProvider:render `, { authReady });
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error("useConfigContext must be used within a ConfigProvider");
  }
  return context;
};

export const MockConfigProvider = ({
  children,
  userId,
}: {
  children: ReactNode;
  userId?: string;
}) => {
  const value: ConfigContextInterface = {
    // @ts-ignore
    supabase: {},
    userProfile: {
      id: userId ?? "1",
      email: "sample@co.com",
      role: "ADMIN",
      organizationRole: "MANAGER",
      organizationId: "1",
      organizationName: "Sample Org",
      organizationMember: [
        {
          id: "1",
          name: "Sample Org",
          logo: null,
        },
      ],
    },
    supabaseSession: null,
    config: {},
    organizationId: "1",
    organizationData: { name: "Sample Org" },
    expandSideMenu: true,
    reload: async () => {},
    setError: () => {},
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
