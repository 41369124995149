import { ShortMessage } from "~/components/common/ui/ShortMessage";
import { Button } from "~/components/common/ui/button";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";

interface Props {
  onReload: () => void;
  error: Error;
}

export const ErrorBoundaryComponent = ({ onReload, error }: Props) => {
  const router = useRouter();
  const supabase = useSupabaseClient();

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
    } else {
      // await router.replace("/login");
      router.reload();
    }
  };

  const logoutOption = error.message.includes(" (C:6");
  return (
    <ShortMessage title="Error" text={error.message} icon="error">
      <div className="flex flex-row gap-4">
        {logoutOption && (
          <Button
            variant="destructive"
            onClick={logout}
            className="w-fit px-14 mx-auto"
          >
            Logout
          </Button>
        )}
        <Button
          variant="primary"
          onClick={onReload}
          className="w-fit px-14 ml-auto"
        >
          Reload
        </Button>
      </div>
    </ShortMessage>
  );
};
