export const isSSR = () => typeof window === "undefined";

export const isDev = () =>
  (process?.env?.UI_URL ? process.env.UI_URL : window.location.host).includes(
    "localhost",
  );

export const isStaging = () =>
  (process?.env?.UI_URL ? process.env.UI_URL : window.location.host).includes(
    "staging",
  );
