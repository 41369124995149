import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const NumOrZero = (num: number | null | undefined): number =>
  Number.isFinite(num ?? 0) ? num ?? 0 : 0;

export function truncateString(str: string, maxlength: number) {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + "." : str;
}
