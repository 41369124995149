import type { ReactNode } from "react";
import { createContext } from "react";
import { SidebarMenu } from "~/components/layout/SidebarMenu";
import { Header } from "~/components/layout/Header";
import { Footer } from "~/components/layout/Footer";
import { useConfigContext } from "~/components/common/config/config-context";
import React, { useState } from "react";
import { cn } from "~/lib/utils";
import type { BreadCrumbItem } from "~/components/layout/breadcrumb-context";
import { BreadcrumbContext } from "~/components/layout/breadcrumb-context";

export const Layout = ({ children }: { children: ReactNode }) => {
  // const pageDisplayName = children?.type.displayName ?? "-";

  const { userProfile, organizationId } = useConfigContext();
  const [collapsedSidebar, setCollapsedSidebar] = useState(
    window.innerWidth < 768 ? true : false,
  );

  const handleMenuItemClick = () => {
    if (window.innerWidth < 768) {
      setCollapsedSidebar(true);
    }
  };

  const [breadcrumb, setBreadcrumb] = useState<BreadCrumbItem[]>([]);

  if (userProfile) {
    return (
      <BreadcrumbContext.Provider value={setBreadcrumb}>
        <div className="flex h-screen flex-row">
          <SidebarMenu
            organizationId={organizationId}
            collapsed={collapsedSidebar}
            userProfile={userProfile}
            onMenuItemClick={handleMenuItemClick}
          />
          <div
            className={cn(
              "scrollbar-thin relative flex w-full flex-col overflow-x-hidden bg-appBackground",
            )}
          >
            <Header
              onSidebarToggleClick={() =>
                setCollapsedSidebar(!collapsedSidebar)
              }
              userProfile={userProfile}
              breadcrumb={breadcrumb}
            />
            <main className="m-4 mt-11">{children}</main>
            <div className="flex-1">
              <div className="flex h-full items-end">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </BreadcrumbContext.Provider>
    );
  }

  return children;
};
