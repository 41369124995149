import { LoadingIcon } from "~/components/landing-page/parts/Icons";
import { cn } from "~/lib/utils";

interface LoadingProps {
  className?: string;
}

export const Loading = ({ className }: LoadingProps) => {
  return (
    <div
      className={cn(
        "absolute left-0 right-0 top-0 flex h-full items-center justify-center",
        className,
      )}
    >
      <LoadingIcon />
    </div>
  );
};
