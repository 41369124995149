import type { ReactNode } from "react";
import { AlertTriangle, Check, HelpCircle, Info, XCircle } from "lucide-react";
import { cn } from "~/lib/utils";
import { ScrollArea } from "~/components/ui/scroll-area";

interface Props {
  icon?: "warning" | "error" | "info" | "question" | "none";
  title: string;
  text: string;
  children?: ReactNode;
}

export const ShortMessage = ({ title, text, children, icon }: Props) => {
  const iconMap = {
    warning: <AlertTriangle className="w-10 h-10" />,
    error: <XCircle className="w-10 h-10" />,
    info: <Info className="w-10 h-10" />,
    question: <HelpCircle className="w-10 h-10" />,
    check: <Check className="w-10 h-10" />,
    none: null,
  };

  console.log("text: " + text);

  return (
    // top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4
    <div className="absolute flex justify-center items-center h-screen w-full">
      <div className="text-left flex flex-col gap-2 w-[60%]">
        <div>
          <div className="text-[#1F62F2] mx-auto w-fit">
            {iconMap[icon ?? "check"]}
          </div>
          <h1 className="text-2xl font-bold text-[#1F62F2]">{title}</h1>
        </div>
        <ScrollArea>
          <p className="text-md font-medium max-h-80  whitespace-break-spaces">
            {text}
          </p>
        </ScrollArea>
        {children}
      </div>
    </div>
  );
};
