export const getAvatarInitials = (name: string | null | undefined) => {
  // Split name into array
  if (!name) {
    return "?";
  }

  const nameSplit = name.split(" ");

  // Get first letters of first and last name
  let initials = nameSplit[0]?.substring(0, 1).toUpperCase() ?? "";

  if (nameSplit.length > 1) {
    initials += nameSplit[nameSplit.length - 1]?.substring(0, 1).toUpperCase();
  }

  return initials ?? "?";
};
