import {
  createContext,
  type ReactNode,
  useContext,
  useLayoutEffect,
  useState,
} from "react";

export interface BreadCrumbItem {
  label: string;
  href?: string;
}

type setBreadcrumb = (breadcrumb: BreadCrumbItem[]) => void;

export const BreadcrumbContext = createContext<setBreadcrumb>(() => {});

export const useBreadcrumb = (breadcrumb: () => BreadCrumbItem[]) => {
  const setBreadcrumb = useContext(BreadcrumbContext);
  useLayoutEffect(() => {
    setBreadcrumb(breadcrumb());
  }, [setBreadcrumb, breadcrumb]);
};
